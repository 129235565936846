import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';
import AdContainer from '../../../components/ad/AdContainer';
import {
  BottomNav,
  HonoreesListItem,
  PreviousYears,
  WinnersListItem
} from './components';
import { airtableMarkdownToHtml } from '../../../../utils/airtable';
import clsx from 'clsx';

const DEFAULT_YEAR = 2024;
const AD_BREAK_FREQUENCY = 10;

const InnovationByDesignListPage = ({ match, franchisePage }) => {
  const year = match?.params?.year;
  const winners = franchisePage?.data?.externalList?.Winners;
  const honorees = franchisePage?.data?.externalList?.Honorees;
  const metadata = franchisePage?.data?.externalList?.Metadata[0];
  const [activeTab, setActiveTab] = useState(0); // 0 -> winners; 1 -> honorees
  const tabs = ['WINNERS', 'HONOREES'];
  const thisYear = year === 'list' ? DEFAULT_YEAR : year;

  return (
    <main id={`ibd__listPage--${thisYear}`}>
      <section className={clsx(['intro'])}>
        <div className={clsx(['intro-container'])}>
          <div className={clsx(['franchise-title'])}>
            <h1 className={clsx(['franchise-title__headline'])}>
              {metadata.PageTitle}
            </h1>
            <div className={clsx(['franchise-title__subhead'])}>
              {metadata.Subhead}
            </div>
          </div>

          <picture className={clsx(['franchise-logo'])}>
            {' '}
            {/* Smallest first */}
            <source
              media="(max-width: 449px)"
              srcSet={metadata['featureimage (mobile)']}
            />
            <source
              media="(min-width: 450px)"
              srcSet={metadata['featureimage (desktop)']}
            />
            <img
              src={metadata['featureimage (desktop)']}
              alt={`Innovation by Design ${thisYear}`}
            />
          </picture>

          <div className={clsx(['intro-blurb-container'])}>
            <div className={clsx(['intro-blurb-icon'])}>
              <img
                src={metadata['Blurb image']}
                alt="Innovation by Design 2024"
              />
            </div>

            <span
              className={clsx(['intro-blurb-text'])}
              dangerouslySetInnerHTML={{
                __html: airtableMarkdownToHtml(metadata.Blurb)
              }}
            />
          </div>
        </div>
      </section>

      <section className={clsx(['list-container'])}>
        <nav className={clsx(['list-tab-menu'])}>
          {tabs.map((tab, idx) => (
            <div
              className={clsx([
                'list-tab-menu-item',
                activeTab === idx && 'active'
              ])}
              onClick={() => setActiveTab(idx)}
              key={`tab-${idx}`}
            >
              {tab}
            </div>
          ))}
        </nav>

        <div className={clsx(['list-content-container'])}>
          {activeTab === 0 &&
            winners.map((company, idx) => (
              <Fragment>
                {idx !== 0 &&
                  idx % AD_BREAK_FREQUENCY === 0 && (
                    <div
                      className={clsx(['list-content-ad'])}
                      key={`ad-at-index-${idx}`}
                    >
                      <AdContainer type="break" />
                    </div>
                  )}

                <WinnersListItem {...company} key={`winner-${idx}`} />
              </Fragment>
            ))}

          {activeTab === 1 &&
            honorees.map((honoree, idx) => (
              <Fragment>
                {idx !== 0 &&
                  idx % AD_BREAK_FREQUENCY === 0 && (
                    <div
                      className={clsx(['list-content-ad'])}
                      key={`ad-at-index-${idx}`}
                    >
                      <AdContainer type="break" />
                    </div>
                  )}

                <HonoreesListItem {...honoree} key={`honoree-${idx}`} />
              </Fragment>
            ))}
        </div>
      </section>

      <div className={clsx(['after-list-content'])}>
        <section className={clsx(['application-cta'])}>
          <h3>{metadata.registerTitle}</h3>
          <span>{metadata.registerCopy}</span>
          <a href={metadata.registerURL} target="_blank">
            {metadata.registerCTACopy}
          </a>
        </section>

        <section className={clsx(['view-previous-years'])}>
          <h3>{metadata.pastYearsTitle}</h3>
          <PreviousYears currentYear={thisYear} />
        </section>

        <section
          className={clsx(['franchise-credits'])}
          dangerouslySetInnerHTML={{
            __html: airtableMarkdownToHtml(metadata.Contributors)
          }}
        />
      </div>

      <BottomNav
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        year={thisYear}
        socialShareText={metadata['Social Share Text']}
      />

      <div className={clsx(['background-image'])} />
    </main>
  );
};

function mapStateToProps(state = {}) {
  return {
    franchisePage: state.franchisePage
  };
}

InnovationByDesignListPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      year: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  franchisePage: PropTypes.shape({
    data: PropTypes.shape({
      externalList: PropTypes.shape({
        winners: PropTypes.shape([]).isRequired,
        honorees: PropTypes.shape([]).isRequired,
        metadata: PropTypes.shape([]).isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(connect(mapStateToProps)(InnovationByDesignListPage));
